<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="userList"
      :items-per-page="10"
      class="elevation-1 mt-2"
      :hide-default-footer="true"
      dense
      disable-pagination
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>List Users</v-toolbar-title>
          <v-divider
            class="mx-4" inset vertical
          ></v-divider>
          <v-col cols="3" class="tmc-global-config">
            <v-row>
              <v-select
                :items="timePeriodList"
                v-model="globalSettingItem.logoutTimePeriod"
                label="Logout Time Period"
                item-value="timePeriod"
                item-text="description"
                dense
                class="mx-2"
              ></v-select>
            </v-row>
          </v-col>
          <v-col cols="3" class="tmc-global-config">
            <v-row>
              <v-select
                :items="passExpList"
                v-model="globalSettingItem.passExpPeriod"
                label="Password Expiration Period"
                item-value="expPeriod"
                item-text="description"
                dense
                class="mx-2"
              ></v-select>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row>
              <v-btn
                color="primary"
                @click="updateConfig"
              >Update Config
            </v-btn>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <NewEditDialog
            :canAdd="true"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.roles`]="{ item }">
        <td>{{item.roles.map((r) => rolesConst[r])}}</td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small class="mr-2 tmc-edit-user-icon"
          @click="editItem(item)"
        >
          {{icons.mdiPencil}}
        </v-icon>
        <v-icon
          small class="mr-2"
          @click="deleteItem(item)"
        >
          {{icons.mdiDelete}}
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  mdiDelete,
  mdiPencil,
} from '@mdi/js';
import { mapState } from 'vuex';
import NewEditDialog from './NewEditDialog.vue';
import {
  usersLogoutTimePeriods,
  usersPassExpPeriods,
} from '../../util/shared/tmc-global';

export default {
  name: 'UserList',
  components: {
    NewEditDialog,
  },
  data: () => ({
    icons: {
      mdiDelete,
      mdiPencil,
    },
    headers: [
      { text: 'User', value: 'username' },
      { text: 'Roles', value: 'roles' },
      { text: 'Action', value: 'actions', sortable: false },
    ],
    timePeriodList: usersLogoutTimePeriods,
    passExpList: usersPassExpPeriods,
  }),
  created() {
    this.$store.dispatch('UserAdministration/uploadMkdir');
    this.$store.dispatch(
      'SystemConfig/loadConfig',
      { requiredScope: 'global' },
    );
  },
  computed: {
    ...mapState({
      globalSettingItem: (state) => state.SystemConfig.globalSettingItem || {},
    }),
    userList() {
      return this.$store.state.UserAdministration.userList;
    },
    rolesConst() {
      return this.$store.state.UserAdministration.rolesConst;
    },
  },
  methods: {
    editItem(item) {
      this.$store.commit('UserAdministration/setEditedIndex', this.userList.indexOf(item));
      this.$store.commit('UserAdministration/setEditedItem', item);
      this.$store.commit('UserAdministration/setDialog', true);
    },
    async deleteItem(item) {
      if (window.confirm('Are you sure you want to delete this user?')) {
        this.$store.dispatch('UserAdministration/deleteUser', item);
      }
    },
    updateConfig() {
      if (window.confirm('Are you sure want to update this configuration?')) {
        const payload = { calledScope: 'global', item: this.globalSettingItem };
        this.$store.dispatch('SystemConfig/updateConfig', payload);
      }
    },
  },
};
</script>

<style lang="sass">
.tmc-global-config
  margin-top: 2%

</style>
