<template>
  <v-dialog
    v-model="dialog" max-width="70vw"
    @keydown.enter="save"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="canAdd"
        class="mx-2" color="primary"
        fab icon small
        v-bind="attrs" v-on="on"
        @click="newItem"
      >
        <v-icon>
          {{icons.mdiPlus}}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="subtitle-1">{{ dialogTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container class="grey lighten-5">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.username"
                label="Username"
                id="userAdminSetUsername"
                name="userAdminSetUsername"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs" v-on="on"
                    v-model="editedItem.password"
                    label="Password"
                    type="password"
                    id="userAdminSetPassword"
                    name="userAdminSetPassword"
                    autocomplete="new-password"
                  ></v-text-field>
                </template>
                <span>This user will be asked to change their password when they login.</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.fullname"
                label="Fullname"
                id="userAdminSetFullname"
                name="userAdminSetFullname"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select id="tmc-user-roles"
                dense
                v-model="editedItem.roles"
                :items="rolesList"
                label="Roles"
                item-value="name"
                item-text="description"
                chips
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-checkbox
                v-model="editedItem.disableUser"
                label="Disable User"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text color="blue darken-1"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          id="userAdministrationSaveBtn"
          text color="blue darken-1"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiPlus,
} from '@mdi/js';

const fields = {
};

export default {
  name: 'NewEditDialog',
  props: ['canAdd'],
  data: () => ({
    icons: {
      mdiPlus,
    },
    defaultItem: { ...fields },
  }),
  computed: {
    editedIndex() {
      return this.$store.state.UserAdministration.editedIndex;
    },
    editedItem() {
      return {
        ...this.$store.state.UserAdministration.editedItem,
      };
    },
    dialogTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
    dialog() {
      return this.$store.state.UserAdministration.dialog;
    },
    rolesList() {
      const { rolesList } = this.$store.state.UserAdministration;
      return rolesList;
    },
    rolesConst() {
      return this.$store.state.UserAdministration.rolesConst;
    },
  },
  created() {
  },
  methods: {
    newItem() {
      this.$store.commit('UserAdministration/setEditedIndex', -1);
      this.$store.commit('UserAdministration/setEditedItem', this.defaultItem);
      this.$store.commit('UserAdministration/setDialog', true);
    },
    close() {
      this.$store.commit('UserAdministration/setDialog', false);
      this.$nextTick(() => {
        this.$store.commit('UserAdministration/setEditedItem', this.defaultItem);
        this.$store.commit('UserAdministration/setEditedIndex', -1);
      });
    },
    save() {
      const userData = { ...this.editedItem };
      userData.pwdhash = userData.password || '';
      this.$delete(userData, 'password');
      this.$store.dispatch('UserAdministration/upsertUser', userData);
      this.close();
    },
  },
};
</script>
