<template>
  <v-container>
    <v-tabs>
        <v-tab>User Admin</v-tab>
        <v-tab-item>
          <UserList />
        </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>

import UserList from '@/components/UserAdministration/UsersList.vue';

export default {
  name: 'Deposits',
  components: {
    UserList,
  },
  created() {
    this.$store.dispatch('UserAdministration/loadUsers');
    this.$store.dispatch('UserAdministration/userEnums');
  },
};
</script>
